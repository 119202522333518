// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "@/channels/index.js"
//import "typeface-inter"
import '@fontsource-variable/inter';
import "@oddcamp/cocoon-vanilla-js";
import $ from "jquery";
import RegistrationFormManager from "@/components/registration";
import TableHelper from "@/components/tableHelper";

//Rails.start()
Turbolinks.start()
ActiveStorage.start()


function lazyLoadImports(){
    if (document.querySelectorAll("[data-registration]").length) {
        import("@/components/registration") // webpack will load this JS async
    }
    if (document.querySelectorAll("table").length) {
        import("@/components/tableHelper") // webpack will load this JS async
    }

    $("[data-open-modal]").click(function (e){
        e.preventDefault();
        let modalTarget= $(this).data('open-modal');
        console.log(modalTarget)
        $("[data-modal='"+modalTarget+"']").toggleClass('hidden');
    })

    $("[data-close-modal]").click(function (e){
        e.preventDefault();
        $(this).closest("[data-modal]").addClass('hidden')
    })

    $("[data-acc-target]").click(function(e){
        e.preventDefault();
        let target = $(this).data('acc-target');
        let indicator = $("[data-acc-indicator='" + target + "']");
        target = $("[data-acc-name='" + target + "']");

        if($(this).data('acc-open') == 'true'){
            target.css('max-height',0);
            indicator.css('transform','rotate(0)')
            $(this).data('acc-open','false')
        }else {
            target.css('max-height',target.get(0).scrollHeight);
            indicator.css('transform','rotate(90deg)')
            $(this).data('acc-open', 'true')
        }

    });

    $("button[name='do_action']").on('click',function(){
        if($(this).data('open-new-window')){
            $(this).parents('form').attr('target','_blank')
        }else {
            $(this).parents('form').attr('target','')
        }
    });

    $("[data-update-form]").on('change',function(e){
        const targetForm = $(this).attr('form');
        if(targetForm){
            $('#'+targetForm).submit();
        }else {
            $(this).parents('form').submit();
        }
    })

    $("[data-toggle-mobile-nav]").click(function (e){
        e.preventDefault();
        let currentState = $(this).data('toggle-mobile-nav');
        if(currentState == 'closed'){
            $(this).find('[data-button-open]').removeClass('block').addClass('hidden');
            $(this).find('[data-button-close]').removeClass('hidden').addClass('block');
            $('[data-main-navi]').removeClass('hidden');

            $(this).data('toggle-mobile-nav','open')
        }else {
            $(this).find('[data-button-open]').removeClass('hidden').addClass('block');
            $(this).find('[data-button-close]').removeClass('block').addClass('hidden');
            $('[data-main-navi]').addClass('hidden');

            $(this).data('toggle-mobile-nav','closed')
        }


    })

}

document.addEventListener("load", lazyLoadImports)
document.addEventListener("turbolinks:load", lazyLoadImports)





import Trix from "trix"
import "@rails/actiontext"