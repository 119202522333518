import $ from "jquery";

export default class RegistrationFormManager {
    constructor() {
        console.log('construct')
    }

    init() {
        this.calculateFee();
        this.attachHandlers();
    }

    attachHandlers() {
        this.calculateFee();
        $('input[type="radio"]').on('change', (e) => {
            this.calculateFee();
        })

        this.switchType();
        $('input[data-switch-type]').on('change', (e) => {
            this.switchType();
        })


    }

    courseForDaySelected(day) {
        const radioGroupName = `registration_form[participations_day${day}]`;
        const $input = $(`input[name="${radioGroupName}"]:checked`);
        return $input.length
            && $input.is(':checked')
            && $input.val() != -1;
    }

    calculateFee() {
        $('[data-all-fees] span').hide();
        if(this.courseForDaySelected(0) && this.courseForDaySelected(1)) {
            $('[data-fee-two-days]').show();
        }else if (this.courseForDaySelected(0) || this.courseForDaySelected(1)) {
            $('[data-fee-one-day]').show();
        } else  {
            $('[data-fee-none]').show();
        }

        return;

        if($('input[registration_form[participations_day1]').is(':checked')) {
        }


        if ($('[data-membership]').is(':checked') && $("[data-reduced-fee]").is(':checked')) {
            $('[data-fee-member-reduced]').show();
        } else if ($("[data-reduced-fee]").is(':checked')) {
            $('[data-fee-reduced]').show();
        } else if ($("[data-membership]").is(':checked')) {
            $('[data-fee-member]').show();
        } else {
            $('[data-fee-default]').show();
        }
    }

    switchType() {
        if ($('input[data-switch-type="presence"]:checked').length) {
            $('[data-visible-presence]').removeClass('hidden').find('input').prop('disabled',false);;
            $('[data-visible-online]').addClass('hidden').find('input').prop('disabled',true);
        } else if ($('input[data-switch-type="online"]:checked').length) {
            $('[data-visible-presence]').addClass('hidden').find('input').prop('disabled',true);;
            $('[data-visible-online]').removeClass('hidden').find('input').prop('disabled',false);;
        }

    }
}

document.addEventListener("turbolinks:load", function() {
    const reg = new RegistrationFormManager();
    reg.init();
})
